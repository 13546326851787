/* gallery view */

.imageRow {
    width: 86%;
    table-layout: fixed;
    border-spacing: 10px; 
    overflow-x: auto;
    white-space: nowrap;
    padding-right: 0vw;
    text-align: left;
    margin-left: 7%;
    margin-right: 7%;

    scrollbar-color: rgb(57, 55, 69) rgb(28, 27, 35) ;
    scrollbar-width: thin;
    -ms-overflow-style: scroll;
  }

.scoll-pane::-webkit-scrollbar { 
    display: none;
  }

.imageColumn {
    display: inline-block;
    width: 10%;
    padding: 2%;
}


.artSubtitle {
    text-align: left;
    margin-left: 7%;
    text-decoration: none;
}

.overlay{
    position: fixed; 
    width: 100%; 
    height: 100%; 
    top: 0px; 
    left: 0px; 
    background-color: #000; 
    opacity: 0.7;
    filter: alpha(opacity = 70) !important;
    display: none;
    z-index: 100;
    
}

.overlayContent{
    position: fixed; 
    width: 25%;
    top: 15%;
    left: 37.5%;
    
    display: none;
    overflow: hidden;
    z-index: 100;
}

@media only screen and (max-width: 600px) {

    .imageColumn {
        display: inline-block;
        width: 20%;
        padding: 6%;
    }

    .imageRow {
        padding-left: 0%;
      }


    .overlayContent {
        width: 60%;
        top: 15%;
        left: 20%;
    }
  }

/*table view */

.messFormat{
    position: absolute;
    width: 10%;
    height: auto;
    
    }

.messFormat img {
    width: 90%;
    padding: 10px;
}

.messFormat img:hover{
    background: -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-position:  0% 0%, 100% 100%, 0% 100%, 100% 0%; 
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
}

.messFormatAnimate {
    position: absolute;
    width: 10%;
}

.messFormatAnimate img {
    width: 90%;
    background: -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0%; 
    padding: 10px;
    -webkit-animation: border-dance 2s infinite linear;
    -moz-animation: border-dance 2s infinite linear;
    animation: border-dance 2s infinite linear;
}

@-webkit-keyframes border-dance{
    0%{background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0%;}
    100%{background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;}
}
@-moz-keyframes border-dance{
    0%{background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0%;}
    100%{background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;}
}
@keyframes border-dance{
    0%{background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0%;}
    100%{background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;}
}
 
.imgContainer {
    background-color: white;
}
  
  

.canvas {
    padding-left: 10%;
    width: 80%;
    height: 35%;
    position: fixed;
}

.artTableTooShort {
    display: none;
}

@media only screen and (max-width: 400px) {
    .messFormat {
        width: 20%;
    }
  }

@media only screen and (max-height: 450px) {
    .tablePage {
        display: none;
    }
    .artTableTooShort {
        display: block;
    }
  }

/* other */

.galleryButton {
    color: rgb(110,110,110)
}